<template>
  <div class="bgcard">
    <p v-if="active">{{ $t("lang.AddServer") }}</p>
    <p v-else>{{ $t("lang.EditServer") }}</p>
    <div>
      <div class="information">
        <div class="basic">
          <div>{{ $t("lang.EssentialInformation") }}</div>
          <span></span>
        </div>
        <div class="basic_a">
          <div class="basic_input basic_b">
            <label
              ><span class="spanImpt">*</span
              >{{ $t("lang.ServerSerial") }}：</label
            >
            <el-input v-model="ruleForm.mid" maxlength="32"></el-input>
          </div>
          <div class="basic_input basic_b">
            <label
              ><span class="spanImpt">*</span
              >{{ $t("lang.ServerName") }}：</label
            >
            <el-input v-model="ruleForm.name" maxlength="32"></el-input>
          </div>
          <div class="basic_input select">
            <label
              ><span class="spanImpt">*</span>{{ $t("lang.type") }}：</label
            >
            <el-select
              v-model="ruleForm.modelType"
              :placeholder="$t('lang.Please')"
              popper-class="select_a"
              @change="ChoiceType"
            >
              <el-option :label="$t('lang.Cloudserver')" :value="1"></el-option>
              <el-option :label="$t('lang.Localserver')" :value="2"></el-option>
            </el-select>
          </div>
          <div class="basic_input basic_b">
            <label>{{ $t("lang.Location") }}：</label>
            <el-input v-model="ruleForm.location"></el-input>
          </div>
          <div class="basic_input basic_b">
            <label>{{ $t("lang.ServerNetwork") }}：</label>
            <el-input v-model="ruleForm.network"></el-input>
          </div>
          <!-- <div class="basic_input select">
            <label>{{ $t("lang.RunningState") }}：</label>
            <el-select
              :disabled="true"
              v-model="ruleForm.status"
              :placeholder="$t('lang.Please')"
              popper-class="select_a"
            >
              <el-option :label="$t('lang.Deleted')" :value="0"></el-option>
              <el-option :label="$t('lang.normal')" :value="1"></el-option>
              <el-option :label="$t('lang.fault')" :value="2"></el-option>
              <el-option :label="$t('lang.maintain')" :value="3"></el-option>
              <el-option :label="$t('lang.offline')" :value="4"></el-option>
            </el-select>
          </div> -->
          <div class="basic_input select">
            <label
              ><span class="spanImpt">*</span
              >{{ $t("lang.Operationtype") }}：</label
            >
            <el-select
              :disabled="state"
              v-model="ruleForm.type"
              popper-class="select_a"
            >
              <el-option :label="$t('lang.lease')" :value="2"></el-option>
              <el-option :label="$t('lang.sale')" :value="3"></el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div class="information">
        <div class="basic">
          <div>{{ $t("lang.ConfigureInformation") }}</div>
          <span></span>
        </div>
        <div class="basic_a">
          <div class="basic_input basic_b">
            <label
              ><span class="spanImpt">*</span
              >{{ $t("lang.IpAddress") }}：</label
            >
            <el-input v-model="ruleForm.ip"></el-input>
          </div>
          <!-- <div class="basic_input basic_b">
						<label>{{$t('lang.VisitAddress')}}：</label>
						<el-input v-model="ruleForm.accessIP"></el-input>
					</div> -->
          <div class="basic_input basic_b">
            <label
              ><span class="spanImpt">*</span>{{ $t("lang.port") }}：</label
            >
            <el-input v-model="ruleForm.port"></el-input>
          </div>
        </div>
      </div>
      <div class="information">
        <div class="basic">
          <div>{{ $t("lang.ResourceStatus") }}</div>
          <span></span>
        </div>
        <div class="basic_a">
          <div class="basic_input basic_b">
            <label>{{ $t("lang.CpuNumber") }}：</label>
            <el-input v-model="ruleForm.cpuNum"></el-input>
            <span>{{ $t("lang.nucleus") }}</span>
          </div>
          <div class="basic_input basic_b">
            <label>{{ $t("lang.broadband") }}：</label>
            <el-input v-model="ruleForm.bwtotal"></el-input>
            <span>M</span>
          </div>
          <div class="basic_input basic_b">
            <label>{{ $t("lang.disk") }}：</label>
            <el-input v-model="ruleForm.storageTotal"></el-input>
            <span>G</span>
          </div>
          <div class="basic_input basic_b">
            <label>{{ $t("lang.Memory") }}：</label>
            <el-input v-model="ruleForm.memTotal"></el-input>
            <span>G</span>
          </div>
        </div>
      </div>
      <div class="btnstyle">
        <button @click="preservation">{{ $t("lang.preservation") }}</button>
        <button @click="returns">{{ $t("lang.return") }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import * as server from "@/api/server.js";
import * as RegExp from "@/ui-utils/RegExp.js";
export default {
  data() {
    return {
      ruleForm: {
        mid: "",
        name: "",
        modelType: "",
        location: "",
        network: "",
        status: 4,
        type: "",
        ip: "",
        //accessIP:'',
        port: "",
        cpuNum: "",
        bwtotal: "",
        storageTotal: "",
        memTotal: "",
        id: "",
      },
      active: true,
      state: false, //是否禁用
      page: 1,
    };
  },
  created() {
    if (this.$route.query.data) {
      let dataObg = JSON.parse(this.$route.query.data);
      console.log(dataObg);
      this.ruleForm = dataObg;
	  if(this.ruleForm.modelType==1){
		  this.ruleForm.type=2
		  this.state=true
	  }
      this.ruleForm.storageTotal = this.ruleForm.storageTotal / 1024 / 1024;
      this.ruleForm.memTotal = this.ruleForm.memTotal / 1024 / 1024;
      // this.ruleForm.mid = dataObg.mid
      // this.ruleForm.name = dataObg.name
      // this.ruleForm.modelType = dataObg.modelType
      // this.ruleForm.location = dataObg.location
      // this.ruleForm.network = dataObg.network
      // this.ruleForm.status = dataObg.status
      // this.ruleForm.type = dataObg.type
      // this.ruleForm.ip = dataObg.ip
      // this.ruleForm.port = dataObg.port
      // this.ruleForm.cpuNum = dataObg.cpuNum
      this.active = false;
      this.page = this.$route.query.page;
    }
  },
  methods: {
    //判断选择的类型
    ChoiceType(row) {
      if (row == 1) {
        this.state = true;
        this.ruleForm.type = 2;
      } else {
        this.state = false;
      }
    },
    //保存内容
    async preservation() {
      let adminemail = sessionStorage.getItem("user");
      if (adminemail) {
        this.ruleForm.adminEmail = JSON.parse(adminemail).email;
      }
      let flig = true;
      // for (let key in this.ruleForm) {
      //   if (this.ruleForm[key] == "") {
      //     flig = false;
      //   }
      // }

      if (!flig) {
        this.$message.error(this.$t("lang.Pleaseinputtheinformation"));
        return false;
      }
      if (this.ruleForm.mid == "") {
        this.$message.error(this.$t("lang.Pleaseinputtheinformation"));
        return false;
      }
			if(RegExp.words.test(this.ruleForm.mid) || RegExp.special.test(this.ruleForm.mid)){
						this.$message.error(this.$t("lang.Theserialnumbercannot"));
						return false;
			}
			if(this.ruleForm.mid.length!=32&&this.ruleForm.mid.length<16 ||this.ruleForm.mid.length!=32&&this.ruleForm.mid.length>16){
				this.$message.error(this.$t("lang.Theserial"));
				return false;
			}
      if (this.ruleForm.type == "") {
        this.$message.error(this.$t("lang.Pleaseinputtheinformation"));
        return false;
      }
      if (this.ruleForm.name == "") {
        this.$message.error(this.$t("lang.Pleaseinputtheinformation"));
        return false;
      }
      if (
        RegExp.words.test(this.ruleForm.mid) ||
        RegExp.special.test(this.ruleForm.mid)
      ) {
        this.$message.error(this.$t("lang.Theserialnumbercannot"));
        return false;
      }
      if (!RegExp.ip.test(this.ruleForm.ip)) {
        this.$message.error(this.$t("lang.Pleaseenterthecorrect"));
        return false;
      }
      /* if(!RegExp.ip.test(this.ruleForm.accessIP)){
					this.$message.error(this.$t('lang.Pleaseenterthecorrectaccess'))
					return false
				} */
      if (!RegExp.port.test(this.ruleForm.port)) {
        this.$message.error(this.$t("lang.Pleaseenterthecorrectport"));
        return false;
      }
      if (this.ruleForm.modelType == "") {
        this.$message.error(this.$t("lang.Pleaseselecttype"));
        return false;
      }
      if (this.ruleForm.cpuNum != "") {
        if (!RegExp.integer.test(this.ruleForm.cpuNum)) {
          this.$message.error(this.$t("lang.Pleaseinputthecorrect"));
          return false;
        }
      }

      if (this.ruleForm.bwtotal != "") {
        if (!RegExp.integer.test(this.ruleForm.bwtotal)) {
          this.$message.error(this.$t("lang.Pleaseenterthecorrectsize"));
          return false;
        }
      }

      if (this.ruleForm.storageTotal != "") {
        if (!RegExp.integer.test(this.ruleForm.storageTotal)) {
          this.$message.error(this.$t("lang.Pleaseenterthecorrectdisk"));
          return false;
        }
      }

      if (this.ruleForm.memTotal != "") {
        if (!RegExp.integer.test(this.ruleForm.memTotal)) {
          this.$message.error(this.$t("lang.Pleaseenterthecorrectmemory"));
          return false;
        }
      }

      let data = await server.add(this.ruleForm);
      if (data) {
        this.$message.success(this.$t("lang.addsuccess"));
        this.$router.push({
          path: "serverlist",
        });
      }
    },
    //返回
    returns() {
      this.$router.push({
        path: "serverlist?page=" + this.page,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.spanImpt {
  padding-right: 0.05rem;
  color: #f56c6c !important;
}
.bgcard {
  border: 0px;
  box-sizing: border-box;
  padding: 0.3rem;
  border-radius: 0.02rem;
  background: #222628;

  p {
    color: #dbdbdb;
    font-size: 0.18rem;
    font-weight: bold;
  }

  .information:first-child {
    margin-top: 0.37rem;
  }

  .information {
    margin-top: 0.6rem;
  }
}

.basic {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;

  div {
    color: #dbdbdb;
    font-size: 0.16rem;
  }

  span {
    margin-left: 0.14rem;
    width: 80%;
    border-top: 0.01rem solid #797979;
  }
}

.basic_a {
  width: 80%;
  margin-left: 0.78rem;
  font-size: 0.15rem;
  display: flex;
  flex-flow: row wrap;

  .basic_input {
    margin-top: 0.2rem;
    display: flex;
    align-items: center;
    margin-right: 0.8rem;

    label {
      text-align: right;
      width: 1.3rem;
      margin-right: 0.1rem;
      color: #dbdbdb;
      font-weight: bold;
    }
    span {
      display: inline-block;
      margin-left: 0.05rem;
      color: #dbdbdb;
    }
    .el-input,
    .el-select {
      width: 2.5rem;
    }
  }
}

.btnstyle {
  margin-top: 0.3rem;

  button {
    outline-style: none;
    border: none;

    &:first-of-type {
      width: 1.31rem;
      height: 0.54rem;
      background: #307b37;
      border-radius: 0.04rem;
      font-size: 0.18rem;
      font-family: PingFang SC;
      font-weight: 500;
      color: #ffffff;
      margin-right: 0.16rem;
    }

    &:last-of-type {
      width: 1.31rem;
      height: 0.54rem;
      background: #222628;
      border: 0.01rem solid #307b37;
      border-radius: 0.04rem;
      font-size: 0.18rem;
      font-family: PingFang SC;
      font-weight: 500;
      color: #307b37;
    }
  }
}
</style>
